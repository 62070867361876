<template>
  <div style="min-height:100vh" v-loading="loading">
    <div class="listBox">
      <ul class="list flex a_c">
        <li v-for="(item, i) in resultList" :key="i">
         <div>
            <img
              style="cursor: pointer"
              @click="jumpToShop(i)"
              width="386px"
              height="386px"
              :src="item.homepage_img_path"
              alt
            />
          </div>
          <div class="shopBox">
            <div class="p1">{{ item.title }}</div>
            <div class="p2">{{ item.jieshao }}</div>
          </div>
          <div class="shopBox_bot flex j_s a_c">
            <div class="price">￥{{ item.price}}</div>
            <div class="flex a_c">
              <img
                @click="shopCartAdd(i)"
                src="https://static.5t1800.com/s2/2021/06/15/60c81a3e68d0c.png"
                alt
              />
              <div @click="buy(i)" class="buy">立即购买</div>
            </div>
          </div>
        </li>
        <div v-show="resultList.length == 0 && !loading">暂无相关商品！</div>
      </ul>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import storage from "@/utils/storage";
export default {
  data() {
    return {
      resultList: [
        // {
        //   img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        //   name: "保湿面膜立减80元保湿面膜立减80元",
        //   nameEng: "baoshimianmo",
        //   type: "保湿面膜",
        //   volume: "60ml",
        //   price: 1800,
        //   count: 1
        // },
        // {
        //   img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        //   name: "保湿面膜立减80元",
        //   nameEng: "baoshimianmo",
        //   type: "保湿面膜",
        //   volume: "60ml",
        //   price: 1800,
        //   count: 1
        // },
        // {
        //   img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        //   name: "保湿面膜立减80元保湿面膜立减80元",
        //   nameEng: "baoshimianmo",
        //   type: "保湿面膜",
        //   volume: "60ml",
        //   price: 1800,
        //   count: 1
        // },
        // {
        //   img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        //   name: "保湿面膜立减80元",
        //   nameEng: "baoshimianmo",
        //   type: "保湿面膜",
        //   volume: "60ml",
        //   price: 1800,
        //   count: 1
        // },
        // {
        //   img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        //   name: "保湿面膜立减80元保湿面膜立减80元",
        //   nameEng: "baoshimianmo",
        //   type: "保湿面膜",
        //   volume: "60ml",
        //   price: 1800,
        //   count: 1
        // }
      ],
      text: this.$route.params.text,
      loading:false,
      loadNum: 1,
      isLoad: true
    };
  },
  components: {},
  created() {
    this.fixType();
    this.getSearchRes();
    window.onscroll = () => {
      //滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        if (this.isLoad) {
          this.loadNum++;
          console.log(this.loadNum);
          this.getSearchRes();
        }
      }
    };
  },
  methods: {
    //立即购买
    buy(i) {
      var value = this.resultList[i];
      console.log(value);
      var shop = [];
      var settlementInfo = {};
      var goods_params = [],
        goods_info = [];

      goods_params.push({
        id: value.id,
        number: 1,
        skuid: value.sku_id
      });
      goods_info.push({
        title: value.title,
        skuTitle: value.sku_title,
        pic: value.img_path,
        price: value.price,
        number: 1,
        skuid: value.sku_id
      });

      settlementInfo.goods_params = goods_params;
      settlementInfo.goods_info = goods_info;
      settlementInfo.buy_type = 1;
      storage.set("settlementInfo", settlementInfo, true);
      console.log(shop);
      this.routerTo("settlement");
    },
    //点击添加购物车
    shopCartAdd(i) {
      var value = this.resultList[i];
      console.log(value);
      var shop = {},
        goods_params = [];
      goods_params.push({
        id: value.id,
        number: 1,
        skuid: value.sku_id
      });
      shop.img_path = value.img_path;
      shop.skuTitle = value.sku_title;
      shop.title = value.title;
      shop.price = value.price;
      shop.number = 1;
      shop.skuid = value.sku_id;
      shop.goods_params = goods_params;
      console.log(shop);
      this.WYfun.getShopCartSto(shop);
      this.$message({
        message: "添加成功",
        type: "success",
        duration: 1500,
        onClose: () => {
          window.location.reload();
        }
      });
    },
    //点击跳转商品详情
    jumpToShop(i) {
      var saveInfo = this.resultList[i];
      storage.set("productDetails", saveInfo, true);
      this.routerTo("productDetails");
      console.log("wy", saveInfo);
    },
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 9);
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    },
    //获取搜索结果数据
    getSearchRes() {
      this.loading = true
      var params = {
        keys: this.$route.params.text,
        page: this.loadNum,
        limit: 9
      };
      api
        .$getShopList(params)
        .then(res => {
          console.log(res.data.data.list);
          var resultList = this.resultList;
          this.resultList = resultList.concat(res.data.data.list);
          if (res.data.data.list.length == 0) {
            this.isLoad = false;
          }
           this.loading = false
        })
        .catch(error => {
          console.log(error);
           this.loading = false
        });
    }
  }
};
</script>

<style scoped>
.listBox {
  width: 1200px;
  margin: 20px auto;
}
.listBox .list {
  width: 1200px;
  flex-wrap: wrap;
}

.listBox .list li {
  width:386px;
  margin: 0 6px;
  margin-bottom: 20px;
}
.listBox .list li img {
  cursor: pointer;
}
/* 商品详情 */
.shopBox {
  width: 386px;
  box-sizing: border-box;
  padding: 0 10px;
  height: 122px;
}

.shopBox .p1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 20px 0 15px 0;
}

.shopBox .p2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.shopBox_bot {
  height: 47px;
  padding: 10px;
  box-sizing: border-box;
}
.shopBox_bot .buy {
  width: 78px;
  height: 27px;
  background: #000000;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
}
.shopBox_bot .price {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff2742;
}


.shopBox_bot img {
  cursor: pointer;
  width: 28px;
  height: 27px;
  margin-right:10px;
}
</style>

